import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Spinner,
} from "@chakra-ui/react";

import { kratos } from "@digitalsurance/auth";
import { getNodeId } from "@ory/integrations/ui/index.mjs";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "src/hooks/useTranslation";
import { translateError } from "src/intl/translate-error";

export type Flows =
  | kratos.LoginFlow
  | kratos.RegistrationFlow
  | kratos.SettingsFlow
  | kratos.VerificationFlow
  | kratos.RecoveryFlow;
export type Props<T> = {
  flow?: Flows;
  onSubmit: (values: T) => Promise<void>;
  groups?: string[];
};

export const Flow = <T extends Flows = any>({
  flow,
  onSubmit,
  groups,
}: Props<T>) => {
  const t = useTranslation();
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    setValue,
  } = useForm<T>();

  useEffect(() => {
    if (!flow) {
      return;
    }
    for (const node of flow.ui.nodes.filter(
      (node) => !groups || groups.includes(node.group)
    )) {
      //@ts-ignore
      setValue(node.attributes.name, node.attributes.value);
    }
    // set csrf-token

    const csrf = flow.ui.nodes.find(
      //@ts-ignore
      (node) => node.attributes.name === "csrf_token"
    )
    if (csrf) {
      //@ts-ignore
      setValue("csrf_token", csrf.attributes.value);
    }

  }, [flow, groups, setValue]);



  if (!flow) {
    return (
      <Box
        pos="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
      >
        <Spinner size="lg" />
      </Box>
    );
  }

  console.log(flow);

  return (
    <Box w="full" mt="auto">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid gap={2}>
          {flow.ui.nodes
            .filter((node) => !groups || groups.includes(node.group))
            .map((node, k) => {
              const id = getNodeId(node);

              //@ts-ignore
              const type = node.attributes.type;
              //@ts-ignore
              const name = node.attributes.name;
              //@ts-ignore
              const required = node.attributes.required;
              //@ts-ignore
              const hidden = node.attributes.type === "hidden";
              //@ts-ignore
              const value = node.attributes.value;

              let placeholder = node.meta.label?.text;

              //TODO: check if this can be configured in kratos instead
              if (placeholder === "ID") {
                placeholder = "Email";
              }

              const messages = node.messages;
              const errorMessage = messages?.find(
                (message) => message.type === "error"
              );
              const transltedErrorMessage =
                errorMessage && translateError(errorMessage);

              //@ts-ignore
              switch (type) {
                case "text":
                case "email":
                case "password":
                case "hidden":
                  return (
                    <FormControl
                      isInvalid={!!errors[name] || !!errorMessage}
                      key={`${id}-${k}`}
                      hidden={hidden}
                    >
                      <FormLabel htmlFor={name}>
                        {
                          // @ts-ignore
                          t(placeholder)
                        }
                      </FormLabel>
                      <Input
                        // variant="filled"
                        type={type}
                        id={name}
                        // placeholder={placeholder}
                        {...register(name, {
                          required: required && t("required"),
                        })}
                      />

                      <FormErrorMessage flexDir="column" alignItems="start">
                        <div>{errors[name]?.message}</div>
                        <div>{transltedErrorMessage?.text}</div>
                      </FormErrorMessage>
                    </FormControl>
                  );

                case "button":
                case "submit":
                  if (value === "microsoft") {
                    return (
                      <Button
                        type="submit"
                        leftIcon={
                          <img src="/ms-symbollockup_mssymbol_19.svg" />
                        }
                        colorScheme="gray"
                        variant="outline"
                      >
                        {t("Sign in with Microsoft")}
                      </Button>
                    );
                  }

                  return (
                    <Button
                      w="full"
                      mt={4}
                      isLoading={isSubmitting}
                      type="submit"
                      key={`${id}-${k}`}
                    >
                      {
                        // @ts-ignore
                        t(placeholder)
                      }
                    </Button>
                  );
                default:
                  return <div key={`${id}-${k}`}>unhandled node</div>;
              }
            })}
        </Grid>
      </form>
      {flow.ui.messages?.map(translateError).map((message, k) => (
        <Box mt={2} color="black" key={message.id}>
          {message.text}
        </Box>
      ))}
    </Box>
  );
};
