import { navigate } from "vike/client/router";
import { Dispatch, SetStateAction } from "react";

const redirectToDashboard = () => {
  const match =
    /(accounts)(?:\.local|\.dev|\.staging)?\.digitalsurance\.co/.exec(
      window.location.href
    );
  if (match) {
    window.location.href = "https://" + match[0].replace(match[1], "dashboard");
  } else {
    navigate("/");
  }
};

// A small function to help us deal with errors coming from fetching a flow.
export function handleGetFlowError<S>(
  flowType: "login" | "registration" | "settings" | "recovery" | "verification",
  resetFlow: Dispatch<SetStateAction<S | undefined>>
) {
  return async (err) => {
    switch (err.response?.data.error?.id) {
      case "session_aal2_required":
        // 2FA is enabled and enforced, but user did not perform 2fa yet!
        window.location.href = err.response?.data.redirect_browser_to;
        return;
      case "session_already_available":
        // User is already signed in, let's redirect them home!
        return redirectToDashboard();
      case "session_refresh_required":
        // We need to re-authenticate to perform this action
        window.location.href = err.response?.data.redirect_browser_to;
        return;
      case "self_service_flow_return_to_forbidden":
        // The flow expired, let's request a new one.
        console.log("The return_to address is not allowed.");
        resetFlow(undefined);
        return navigate("/" + flowType);

      case "self_service_flow_expired":
        // The flow expired, let's request a new one.
        console.log(
          "Your interaction expired, please fill out the form again."
        );
        resetFlow(undefined);
        return navigate("/" + flowType);

      case "security_csrf_violation":
        // A CSRF violation occurred. Best to just refresh the flow!
        console.log(
          "A security violation was detected, please fill out the form again."
        );
        resetFlow(undefined);
        return navigate("/" + flowType);

      case "security_identity_mismatch":
        // The requested item was intended for someone else. Let's request a new flow...
        resetFlow(undefined);
        return navigate("/" + flowType);

      case "browser_location_change_required":
        // Ory Kratos asked us to point the user to this URL.
        window.location.href = err.response.data.redirect_browser_to;
        return;
    }

    switch (err.response?.status) {
      case 410:
      case 404:
      case 403:
        // The flow expired, let's request a new one.
        resetFlow(undefined);
        navigate("/registration");
        return;
    }

    // We are not able to handle the error? Return it.
    return Promise.reject(err);
  };
}

// A small function to help us deal with errors coming from initializing a flow.
export const handleFlowError = handleGetFlowError;
